export const loadScript = (src: string, check?: () => boolean): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (Array.from(document.scripts).findIndex((s) => s.src === src) !== -1) {
      resolve();
    }
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      if (!check) return resolve();
      const interval = setInterval(() => {
        if (check()) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    };
    script.onerror = (error) => reject(error);
    document.head.appendChild(script);
  });
};
