import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {hubspotFormSubmitEvent} from 'utils/gtmEvents';
import {loadScript} from 'utils/loadScript';

type FormData = {
  redirectUrl: string;
  submissionValues: Record<string, string>;
};

type Props = {
  formId: string;
  minHeight?: number;
  onFormSubmitted?: (el: HTMLFormElement, data: FormData) => void;
  onReady?: (el: HTMLFormElement) => void;
};

const LegalNote = styled.p`
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.45);
`;

const FormParent = styled.div<{isReady: boolean}>`
  width: 100%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 42px 32px 8px 32px;
  box-shadow: 0 64px 64px rgba(61, 88, 128, 0.05), 0 32px 32px rgba(61, 88, 128, 0.05),
    0 16px 16px rgba(61, 88, 128, 0.05), 0 8px 8px rgba(61, 88, 128, 0.05),
    0 4px 4px rgba(61, 88, 128, 0.05), 0 2px 2px rgba(61, 88, 128, 0.05);

  & > div {
    opacity: ${({isReady}) => (isReady ? 1 : 0)};
    transition: opacity 0.2s;
  }
`;

export const HubspotForm: React.FC<Props> = ({formId, minHeight, onFormSubmitted, onReady}) => {
  const {language} = useI18next();
  const [isReady, setIsReady] = React.useState(false);

  useEffect(() => {
    loadScript('https://js-eu1.hsforms.net/forms/embed/v2.js', () => window.hbspt).then(() => {
      window.hbspt.forms.create({
        region: 'eu1',
        portalId: '27129750',
        formId,
        onFormReady: (el: HTMLFormElement) => {
          setIsReady(true);
          if (onReady) onReady(el);
        },
        onFormSubmitted: (el: HTMLFormElement, data: FormData) => {
          const {hs_context, ...formData} = data.submissionValues;
          hubspotFormSubmitEvent(formId, formData);
          if (onFormSubmitted) {
            onFormSubmitted(el, data);
          }
        },
        locale: language,
        target: '#hubspotForm'
      });
    });
    const timeout = setTimeout(() => setIsReady(true), 3000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <FormParent isReady={isReady}>
      <div id="hubspotForm" style={{minHeight}} />
      <LegalNote>
        <Trans>
          By providing your contact information, you will receive details about MONEI’s registration
          process, enabled jurisdictions, payment methods, and contract information if applicable.
          You may also receive electronic communications per our Privacy Policy and your consents.
          MONEI Digital Payments S.L., NIF B02660926, will process your data to handle your request
          for information, access, or registration, and manage commercial, contractual, or
          pre-contractual relationships. MONEI’s necessary collaborators may access your data. You
          have the right to access, rectify, oppose, limit, and delete your data, as detailed in our{' '}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </Trans>
      </LegalNote>
    </FormParent>
  );
};
