// i18next-extract-mark-ns-start ecommerce-billing

import Bold from 'components/Bold';
import {Button} from 'components/Button';
import {Card} from 'components/Card';
import CardsContainer from 'components/CardsContainer';
import {Clients, defaultClientList} from 'components/Clients';
import {Content} from 'components/Content';
import 'keen-slider/keen-slider.min.css';
import {HubspotForm} from 'components/HubspotForm';
import IndexBackground from 'components/monei-pay/IndexBackground';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {graphql} from 'gatsby';
import {Link} from 'gatsby-plugin-react-i18next';
import cards from 'images/credit-cards.svg';
import exchangePlatform from 'images/exchange-platform.svg';
import laptop from 'images/laptop.png';
import mobile_payment from 'images/mobile_payment.svg';
import subscriptions_reason_3 from 'images/subscriptions_reasons_3.svg';
import subscriptions_reason_4 from 'images/subscriptions_reasons_4.svg';
import React from 'react';

import styled from 'styled-components';
import useLocalStorage from 'utils/useLocalStorage';
import {ScreenSizes} from '../../types/responsive';

const CardWrapper = styled.div`
  max-width: 255px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const IndexContent = styled.div`
  max-width: 33rem;
  @media (max-width: ${ScreenSizes.lg}) {
    max-width: 32rem;
  }
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 100%;
    padding-top: 0.625rem;
  }
`;

const LogoLink = styled(Link)`
  width: 300px;
  margin-bottom: 20px;
  display: block;
  opacity: 0.6;
  transition: 250ms opacity;

  :hover {
    opacity: 0.8;
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    @media (max-width: ${ScreenSizes.sm}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const BtnDiv = styled.div`
  @media (max-width: ${ScreenSizes.sm}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const FirstSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    margin-bottom: 24px;
  }
`;

const clientsList = defaultClientList.slice(0, 7).map((client) => {
  return {
    ...client,
    width: 180 * client.scale
  };
});

const handleScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const EcommerceBilling: React.FC = () => {
  const [submitCount, setSubmitCount] = useLocalStorage('lead_magnet_submit_count', 1);
  return (
    <>
      <SEO
        path="ecommerce-billing"
        title={'Aumenta hasta un +40% las ventas de tu ecommerce'}
        description={'Guía paso a paso para incrementar la facturación este 2024.'}
      />
      <IndexBackground>
        <Content>
          <Section
            centered
            sx={{
              marginTop: '1.875rem',
              flexDirection: {md: 'column'},
              gap: '90px',
              paddingBottom: '0px'
            }}>
            <IndexContent>
              <LogoLink to="/">
                <img src="https://assets.monei.com/images/logo.svg" alt="MONEI" />
              </LogoLink>
              <LargeSectionHeader underline tagName="h1">
                Aumenta hasta un +40% las ventas de tu ecommerce
              </LargeSectionHeader>
              <h2>Guía paso a paso para incrementar la facturación este 2024.</h2>
              <p>
                Incrementar la conversión de tu tienda online no es cosa de magia. Seguro que ya
                estás familiarizado con aquellas estrategias que claramente impactan positivamente
                en tus ventas. Pero la experiencia en el pago evoluciona a velocidad de la luz. Por
                eso, te traemos esta guía exclusiva para disparar las ventas de tu ecommerce.
              </p>
            </IndexContent>
            <HubspotForm
              formId="37c4ff73-a335-4fc6-8b11-4f0d2ac4bd02"
              minHeight={250}
              onReady={(el) => {
                const input = el.querySelector(
                  'input[name="lead_magnet_submit_count"]'
                ) as HTMLInputElement;
                if (input) {
                  input.value = submitCount;
                }
              }}
              onFormSubmitted={() => {
                setSubmitCount(submitCount + 1);
              }}
            />
          </Section>
        </Content>
      </IndexBackground>
      <Content>
        <Section sx={{paddingBottom: '20px', alignItems: 'center'}}>
          <SectionImage
            src={laptop}
            height={380}
            mobileWidth={300}
            style={{width: 'auto', borderRadius: '20px'}}
            className="hide-on-mobile"
          />
          <div>
            <SectionHeader>
              El 80% de los usuarios que inician el checkout en España, abandonan sin terminar la
              compra
            </SectionHeader>
            Uno de los objetivos de un ecommerce es mejorar la conversión a venta y es primordial
            entender si existe margen de mejora en su checkout. Por ello, hemos creado esta guía
            donde veremos temas cómo: Caídas por la experiencia de pago, precios, adaptabilidad...
            Fallos en la seguridad, falta de confianza... Diseño atractivo y llamadas a la acción
            claras. Alternativas de pago. ...y más de 20 puntos que verás explicados detalladamente
            en la guía.
            <BtnDiv>
              <Button variant="light" style={{marginTop: 30}} onClick={handleScrollToTop}>
                DESCARGA LA GUÍA
              </Button>
            </BtnDiv>
          </div>
        </Section>
      </Content>
      <Content>
        <Section
          centered
          column
          sx={{textAlign: 'center', paddingBottom: '20px', paddingTop: {sm: '90px'}}}>
          <SectionHeader underline style={{textAlign: 'center'}}>
            ¿QUÉ OBTENDRÁS? / BENEFICIOS
          </SectionHeader>
          <p style={{textAlign: 'center'}}>
            Información exclusiva sobre los patrones de conducta de los consumidores a la hora de
            pagar, principales motivos por el que se caen las compras en un ecommerce y las últimas
            tendencias en pagos de la mano de expertos en el sector.
          </p>
          <p style={{textAlign: 'center'}}>
            <strong>
              Así como el KIT de estrategias para profundizar en estrategias de venta para:
            </strong>
          </p>
          <CardsContainer sx={{marginTop: '100px'}}>
            <Card padding="80px 24px 32px 24px">
              <CardImage src={mobile_payment} alt="" style={{top: -45, width: 110}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Bold>Mejorar la seguridad en tu checkout.</Bold>
                </p>
              </CardWrapper>
            </Card>
            <Card padding="80px 32px 32px 32px">
              <CardImage src={cards} alt="" style={{top: -40, width: 80}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Bold>Crear una experiencia de pago más sencilla para el cliente.</Bold>
                </p>
              </CardWrapper>
            </Card>
            <Card padding="80px 32px 32px 32px">
              <CardImage src={subscriptions_reason_3} alt="" style={{top: -48}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Bold>
                    Conocer cuales son los métodos de pago que más lo están petando en el mercado.
                  </Bold>
                </p>
              </CardWrapper>
            </Card>
            <Card padding="80px 32px 32px 32px">
              <CardImage src={exchangePlatform} alt="" style={{width: 90, top: -48}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Bold>Diseño responsive siempre.</Bold>
                </p>
              </CardWrapper>
            </Card>
            <Card padding="80px 32px 32px 32px">
              <CardImage src={subscriptions_reason_4} alt="" style={{top: -48}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Bold>El poder de la personalización en la experiencia del pago ¡y más!</Bold>
                </p>
              </CardWrapper>
            </Card>
          </CardsContainer>
          <SectionActions align="center" style={{marginBottom: 100}}>
            <Button variant="light" style={{marginRight: 15}} onClick={handleScrollToTop}>
              DESCARGA LA GUÍA
            </Button>
          </SectionActions>
        </Section>
      </Content>
      <Content>
        <Section
          centered
          column
          sx={{textAlign: 'center', paddingBottom: '20px', paddingTop: {sm: '90px'}}}>
          <FirstSectionHeader>
            <h2>Marcas que ya confían en nosotros</h2>
          </FirstSectionHeader>
          <Clients clientList={clientsList} />
        </Section>
      </Content>
    </>
  );
};

export default EcommerceBilling;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "ecommerce-billing"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
